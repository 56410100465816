import gsap from 'gsap';

export default {
    mobileTab: function () {
        const titles = document.querySelectorAll('.has-menu-dropdown');

        for (const title of titles) {
            let isOpen = false;
            const navigation = title.nextElementSibling;

            title.addEventListener('click', function () {
                if (window.matchMedia("(max-width: 992px)").matches) {
                    if (isOpen === false) {
                        gsap.to(navigation, .5, {
                            onStart: function () {
                                title.classList.add('has-menu-dropdown--open');
                            },
                            height: 'auto',
                            onComplete: function () {
                                isOpen = true;
                            }
                        });
                    } else {
                        gsap.to(navigation, .5, {
                            height: '0',
                            onComplete: function () {
                                title.classList.remove('has-menu-dropdown--open');
                                isOpen = false;
                            }
                        });
                    }
                }
            });
        }
    },
    languageSwitcher: function () {
        const langSwitcher = document.getElementById('language-switcher');

        if (langSwitcher) {
            langSwitcher.addEventListener('change', function (event) {
               const destination = event.target.value;

               window.location.href = window.location.origin + destination + '/';
            })
        }
    }
}
