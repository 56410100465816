import {tns} from "tiny-slider/src/tiny-slider"

export default {
    newsSlider: function () {
        const sliderEl = document.querySelector('.slider--news');

        if (sliderEl) {
            const slider = tns({
                container: sliderEl,
                items: 1.2,
                slideBy: 1,
                mouseDrag: true,
                autoplay: false,
                speed: 700,
                loop: true,
                animateDelay: 5000,
                nav: true,
                navPosition: 'bottom',
                gutter: 30,
                lazyload: true,
                autoplayButtonOutput: false,
                controls: false,
                responsive: {
                    640: {
                        edgePadding: 20,
                        gutter: 30,
                        items: 1.5
                    },
                    768: {
                        items: 2.5
                    },
                    1024: {
                        items: 4
                    }
                }
            });
            const prev = document.querySelector('.slider--image-news-prev');
            if(prev) {
                prev.addEventListener('click', function () {
                    slider.goTo('prev');
                });
            }
            const next = document.querySelector('.slider--image-news-next');
            if(next) {
                next.addEventListener('click', function () {
                    slider.goTo('next');
                });
            }
        }
    },
    highlightSlider: function () {
        const sliderEl = document.querySelector('.slider--highlight');

        if (sliderEl) {
            const slider = tns({
                container: sliderEl,
                items: 1.2,
                slideBy: 1,
                mouseDrag: true,
                autoplay: false,
                speed: 700,
                loop: true,
                animateDelay: 5000,
                nav: false,
                navPosition: 'bottom',
                gutter: 30,
                lazyload: true,
                autoplayButtonOutput: false,
                controls: false,
                responsive: {
                    640: {
                        edgePadding: 20,
                        gutter: 30,
                        items: 1.5
                    },
                    768: {
                        items: 2.5
                    },
                    1024: {
                        items: 4
                    }
                }
            });
            const prev = document.querySelector('.slider--highlight-prev');
            if(prev) {
                prev.addEventListener('click', function () {
                    slider.goTo('prev');
                });
            }
            const next = document.querySelector('.slider--highlight-next');
            if(next) {
                next.addEventListener('click', function () {
                    slider.goTo('next');
                });
            }
        }
    },
    eventSlider: function () {
        const sliderEvent = document.querySelector('.slider--events');

        if (sliderEvent) {
            const slides = document.querySelectorAll('.slider--events__slide');

            const slider = tns({
                container: sliderEvent,
                items: 1,
                slideBy: 1,
                mouseDrag: false,
                autoplay: false,
                speed: 700,
                animateDelay: 5000,
                nav: true,
                navPosition: 'bottom',
                loop: true,
                lazyload: false,
                center: true,
                autoplayButtonOutput: false,
                controls: false,
            });
            const prev = document.querySelector('.slider--events__controls--prev');
            if(prev) {
                prev.addEventListener('click', function () {
                    slider.goTo('prev');
                });
            }
            const next = document.querySelector('.slider--events__controls--next');
            if(next) {
                next.addEventListener('click', function () {
                    slider.goTo('next');
                });
            }
        }
    },
    casiStudioSlider: function () {
        const sliderEvent = document.querySelector('.slider--casi-studio');

        if (sliderEvent) {
            const slides = document.querySelectorAll('.slider--casi-studio__slide');
            const firstSlide = slides[0];
            const lastSlide = slides[slides.length - 1];

            const boxTitle = document.querySelector('.slider--casi-studio__info-block__title');
            const boxLocation = document.querySelector('.slider--casi-studio__info-block_location');


            const slider = tns({
                container: sliderEvent,
                items: 1.2,
                slideBy: 1,
                mouseDrag: false,
                autoplay: false,
                speed: 700,
                animateDelay: 5000,
                nav: true,
                loop: true,
                gutter: 30,
                lazyload: true,
                center: true,
                autoplayButtonOutput: false,
                controls: false,
                navPosition: 'bottom',
                responsive: {
                    640: {
                        edgePadding: 20,
                        gutter: 30,
                        items: 1.5
                    },
                    768: {
                        items: 2.5,
                    },
                    1024: {
                        items: 3,
                        center: false,
                        nav: false,
                    }
                }
            });

            let populateBox = function (element) {
                const title = element.getAttribute('data-title');
                const description = element.getAttribute('data-description');

                if (title) {
                    boxTitle.innerHTML = title;
                }

                if (description) {
                    boxLocation.innerHTML = description;
                }
            };

            populateBox(slides[1]);

            setTimeout(function () {
                firstSlide.classList.add('first-slide');
                firstSlide.classList.add('translate-anim');
                lastSlide.classList.add('last-slide');
            }, 50);

            const prev = document.querySelector('.slider--casi-studio__controls--prev');
            if(prev) {
                prev.addEventListener('click', function () {
                    slider.goTo('prev');
                });
            }
            const next = document.querySelector('.slider--casi-studio__controls--next');
            if(next) {
                next.addEventListener('click', function () {
                    slider.goTo('next');
                });
            }

            slider.events.on('indexChanged', function () {
                if (window.matchMedia("(min-width: 1024px)").matches) {
                    const animated = sliderEvent.querySelector('.translate-anim');
                    const slides = sliderEvent.querySelectorAll('.tns-slide-active');

                    // getting the second '.active' element due to a tiny-slider strange behaviour
                    const selectedSlide = slides[1];

                    selectedSlide.classList.add('translate-anim');

                    populateBox(slides[2]);

                    animated.classList.remove('translate-anim');

                   /* setTimeout(function () {
                        animated.classList.remove('translate-anim');
                    }, 10)*/
                }
            });
        }
    },
    documentsSlider: function () {
        const sliders = document.querySelectorAll('.slider--documents');

        if (sliders.length) {
            for (const sliderEl of sliders) {

                let slides = sliderEl.querySelectorAll('.slider__slide');

                if (slides.length > 1) {
                    const parent = sliderEl.previousSibling.previousSibling;
                    const sliderPosition = parent.querySelector('.slider-position');
                    let leftPos = sliderPosition.getBoundingClientRect().left;

                    leftPos = leftPos - 15;

                    sliderEl.style.marginLeft = leftPos + 'px';
                    sliderEl.style.paddingRight = leftPos + 'px';

                    const slider = tns({
                        container: sliderEl,
                        items: 1.2,
                        slideBy: 1,
                        mouseDrag: true,
                        autoplay: false,
                        speed: 700,
                        loop: false,
                        animateDelay: 5000,
                        nav: false,
                        gutter: 30,
                        lazyload: true,
                        autoplayButtonOutput: false,
                        controls: false,
                        responsive: {
                            640: {
                                edgePadding: 20,
                                gutter: 30,
                                items: 1.5
                            },
                            768: {
                                items: 2.5
                            },
                            1024: {
                                items: 3,
                            }
                        }
                    });

                    const controls = parent.parentNode.querySelector('.tns-controls');

                    /*if (controls) {
                        controls.style.left = leftPos + 'px';

                        window.addEventListener('resize', function () {
                            sliderEl.style.marginLeft = leftPos + 'px';
                            sliderEl.style.paddingRight = leftPos + 'px';
                            controls.style.left = leftPos + 'px';
                        });
                    }*/
                } else {

                    slides = sliderEl.querySelectorAll('.element-page-document');

                    if (slides.length > 1) {
                        const parent = sliderEl.previousSibling.previousSibling;
                        const sliderPosition = parent.querySelector('.slider-position');
                        let leftPos = sliderPosition.getBoundingClientRect().left;
                        leftPos = leftPos - 120;

                        sliderEl.style.marginLeft = leftPos + 'px';
                        sliderEl.style.paddingRight = leftPos + 'px';

                        const slider = tns({
                            container: sliderEl,
                            items: 1.2,
                            slideBy: 1,
                            mouseDrag: true,
                            autoplay: false,
                            speed: 700,
                            loop: false,
                            animateDelay: 5000,
                            nav: false,
                            gutter: 30,
                            lazyload: true,
                            autoplayButtonOutput: false,
                            controls: false,
                            responsive: {
                                640: {
                                    edgePadding: 20,
                                    gutter: 30,
                                    items: 1.5
                                },
                                768: {
                                    items: 2.5
                                },
                                1024: {
                                    items: 4,
                                }
                            }
                        });

                        const prev = document.querySelector('.slider--documents--prev');
                        if(prev){
                            prev.addEventListener('click', function () {
                                slider.goTo('prev');
                            });
                        }
                        const next = document.querySelector('.slider--documents--next');
                        if(next){
                            next.addEventListener('click', function () {
                                slider.goTo('next');
                            });
                        }

                        const controls = parent.parentNode.querySelector('.tns-controls');
                        if (controls) {
                            window.addEventListener('resize', function () {
                                sliderEl.style.marginLeft = leftPos + 'px';
                                sliderEl.style.paddingRight = leftPos + 'px';
                            });
                        }
                    } else {
                        sliderEl.classList.add('slider--documents--no-slider');
                    }

                }
            }
        }
    },
    productSlider: function () {
        const sliders = document.querySelectorAll('.slider--products');

        if (sliders.length) {
            for (const sliderEl of sliders) {

                const slides = sliderEl.querySelectorAll('.slider__slide');

                if (slides.length > 1) {
                    const parent = sliderEl.previousSibling.previousSibling;

                    const slider = tns({
                        container: sliderEl,
                        items: 1.2,
                        slideBy: 1,
                        mouseDrag: true,
                        autoplay: false,
                        speed: 700,
                        loop: true,
                        animateDelay: 5000,
                        nav: true,
                        gutter: 20,
                        lazyload: true,
                        autoplayButtonOutput: false,
                        controls: false,
                        navPosition: 'bottom',
                        responsive: {
                            640: {
                                edgePadding: 20,
                                gutter: 30,
                                items: 2.2
                            },
                            768: {
                                items: 2.5
                            },
                            1024: {
                                items: 3,
                            },
                            1280: {
                                items: 4,
                            }
                        }
                    });


                    let resizeCardHeight = function () {
                        let height = sliderEl.clientHeight - 30;

                        let slides = sliderEl.querySelectorAll('.slider__slide');

                        for (let slide of slides) {
                            slide.querySelector('.card').style.height = height + 'px';
                        }
                    };

                    resizeCardHeight();

                    const prev = document.querySelector('.tns-control-prev');
                    if(prev){
                        prev.addEventListener('click', function () {
                            slider.goTo('prev');
                        });
                    }
                    const next = document.querySelector('.tns-control-next');
                    if(next){
                        next.addEventListener('click', function () {
                            slider.goTo('next');
                        });
                    }
                } else {
                    sliderEl.classList.add('slider--products--no-slider');
                }
            }
        }
    },
    storySlider: function () {
        const historySliders = document.querySelectorAll('.slider--story');
        let counter = 0;

        if (historySliders.length) {
            for (const historySlider of historySliders) {
                const historySlides = historySlider.querySelectorAll('.slider--story__slide');

                const firstSlide = historySlides[0];
                const lastSlide = historySlides[historySlides.length - 1];

                const boxTitle = document.querySelector('#our-story-gallery-' + counter + ' .slider--events__info-block__title');
                const boxDescription = document.querySelector('#our-story-gallery-' + counter + ' .slider--events__info-block__description');

                const slider = tns({
                    container: historySlider,
                    items: 1.2,
                    slideBy: 1,
                    mouseDrag: false,
                    autoplay: false,
                    speed: 700,
                    animateDelay: 5000,
                    nav: true,
                    loop: true,
                    gutter: 30,
                    lazyload: true,
                    center: true,
                    autoplayButtonOutput: false,
                    controls: false,
                    navPosition: 'bottom',
                    responsive: {
                        640: {
                            edgePadding: 20,
                            gutter: 30,
                            items: 1.5
                        },
                        768: {
                            items: 2.5,
                        },
                        1024: {
                            items: 3,
                            center: false,
                            nav: false,
                        }
                    }
                });

                let populateBox = function (element) {
                    const title = element.getAttribute('data-title');
                    const description = element.getAttribute('data-description');

                    if (title) {
                        boxTitle.innerHTML = title;
                        boxDescription.innerHTML = description;
                    }
                };

                populateBox(historySlides[1]);

                setTimeout(function () {
                    firstSlide.classList.add('first-slide');
                    firstSlide.classList.add('translate-anim');
                    lastSlide.classList.add('last-slide');
                }, 50);

                const prev = document.querySelector('#our-story-gallery-' + counter + ' .slider--events__controls--prev');
                if(prev) {
                    prev.addEventListener('click', function () {
                        slider.goTo('prev');
                    });
                }
                const next = document.querySelector('#our-story-gallery-' + counter + ' .slider--events__controls--next');
                if(next) {
                    next.addEventListener('click', function () {
                        slider.goTo('next');
                    });
                }

                slider.events.on('indexChanged', function () {
                    if (window.matchMedia("(min-width: 1024px)").matches) {
                        const animated = historySlider.querySelector('.translate-anim');
                        const slides = historySlider.querySelectorAll('.tns-slide-active');

                        // getting the second '.active' element due to a tiny-slider strange behaviour
                        const selectedSlide = slides[1];

                        selectedSlide.classList.add('translate-anim');

                        populateBox(slides[2]);

                        animated.classList.remove('translate-anim');

                     /*   setTimeout(function () {
                            animated.classList.remove('translate-anim');
                        }, 10)*/
                    }
                });

                counter++;
            }
        }
    },
    imageBannerSlider: function () {
        const sliders = document.querySelectorAll('.slider--image-banner');

        if (sliders.length) {
            for (const sliderEl of sliders) {
                const id = sliderEl.getAttribute('data-id');

                const slider = tns({
                    container: sliderEl,
                    items: 1,
                    slideBy: 1,
                    mouseDrag: false,
                    autoplay: false,
                    speed: 700,
                    loop: false,
                    controlsPosition: 'bottom',
                    animateDelay: 5000,
                    nav: false,
                    gutter: 30,
                    lazyload: false,
                    swipeAngle: false,
                    autoplayButtonOutput: false,
                    controls: false,
                });
                const prev = document.querySelector('.slider--image-banner-prev');
                if(prev) {
                    prev.addEventListener('click', function () {
                        slider.goTo('prev');
                    });
                }
                const next = document.querySelector('.slider--image-banner-next');
                if(next) {
                    next.addEventListener('click', function () {
                        slider.goTo('next');
                    });
                }
            }
        }
    },
    diconoDiNoiSlider: function () {
        const sliders = document.querySelectorAll('.slider-dicono-di-noi-banner');

        if (sliders.length) {
            for (const sliderEl of sliders) {
                const id = sliderEl.getAttribute('data-id');

                const slider = tns({
                    container: sliderEl,
                    items: 1,
                    slideBy: 1,
                    mouseDrag: false,
                    autoplay: false,
                    edgePadding: 0,
                    speed: 700,
                    animateDelay: 5000,
                    nav: true,
                    loop: true,
                    gutter: 10,
                    lazyload: false,
                    center: true,
                    autoplayButtonOutput: false,
                    controls: false,
                    navPosition: 'bottom',
                    responsive: {
                        640: {
                            items: 2.2
                        }
                    }
                });
                const prev = document.querySelector('.slider-dicono-di-noi-prev');
                if(prev) {
                    prev.addEventListener('click', function () {
                        slider.goTo('prev');
                    });
                }
                const next = document.querySelector('.slider-dicono-di-noi-next');
                if(next) {
                    next.addEventListener('click', function () {
                        slider.goTo('next');
                    });
                }
            }
        }
    },
    sliderVideoHero: function () {
        const sliders = document.querySelectorAll('.video-hero');

        if (sliders.length) {
            for (const sliderEl of sliders) {
                const id = sliderEl.getAttribute('data-id');

                const slider = tns({
                    container: sliderEl,
                    mode: 'gallery',
                    animateIn: 'fadeInDown',
                    animateOut: 'fadeOutDown',
                    animateDelay: 300,
                    items: 1,
                    autoplay: true,
                    loop: true,
                    controls: false,
                    nav: false
                });
            }
        }
    },
    productThumbSlider: function() {
        const element = document.querySelector('.slider-product-thumbnail');

        if(element) {
            const slider = tns({
                container: '.slider-product-thumbnail',
                items: 1,
                slideBy: 'page',
                mode: 'gallery',
                controls: false,
                navPosition: 'bottom',
                navAsThumbnails: true,
                autoplay: false,
                autoplayButtonOutput: false,
                mouseDrag: true
            });

            const thumbs = document.querySelectorAll('.thumbnails__item');

            let counter = 0;

            for (const thumb of thumbs) {
                const index = counter;

                thumb.addEventListener('click', function() {
                    slider.goTo(index);
                });

                counter++;
            }
        }
    },
    techRelatedSlider: function () {
        const sliders = document.querySelectorAll('.slider--tech-related');

        if (sliders.length) {
            for (const sliderEl of sliders) {

                const slides = sliderEl.querySelectorAll('.slider__slide');

                if (slides.length > 1) {
                    /*const parent = sliderEl.previousSibling.previousSibling;
                    const sliderPosition = parent.querySelector('.slider-position');
                    let leftPos = sliderPosition.getBoundingClientRect().left;

                    leftPos = leftPos - 15;

                    sliderEl.style.marginLeft = leftPos + 'px';
                    sliderEl.style.paddingRight = leftPos + 'px';*/

                    const slider = tns({
                        container: sliderEl,
                        items: 1.2,
                        slideBy: 1,
                        mouseDrag: true,
                        autoplay: false,
                        speed: 700,
                        loop: false,
                        animateDelay: 5000,
                        nav: false,
                        gutter: 30,
                        lazyload: true,
                        autoplayButtonOutput: false,
                        controls: true,
                        responsive: {
                            640: {
                                edgePadding: 20,
                                gutter: 30,
                                items: 2.2
                            },
                            768: {
                                items: 2.5
                            },
                            1024: {
                                items: 3,
                            },
                            1280: {
                                items: 3,
                            }
                        }
                    });


                    let resizeCardHeight = function () {
                        let height = sliderEl.clientHeight - 30;

                        let slides = sliderEl.querySelectorAll('.slider__slide');

                        for (let slide of slides) {
                            slide.querySelector('.card').style.height = height + 'px';
                        }
                    };

                    resizeCardHeight();

                    /*const controls = parent.parentNode.querySelector('.tns-controls');

                    if (controls) {
                        controls.style.left = leftPos + 'px';

                        window.addEventListener('resize', function () {
                            sliderEl.style.marginLeft = leftPos + 'px';
                            sliderEl.style.paddingRight = leftPos + 'px';
                            controls.style.left = leftPos + 'px';
                        });
                    }*/
                } /*else {
                    sliderEl.classList.add('slider--products--no-slider');
                }*/
            }
        }
    },
    solutionsSlider: function () {
        const sliderEvent = document.querySelector('.slider--box-tab');

        if (sliderEvent) {
            const slides = document.querySelectorAll('.slider--box-tab__slide');

            const slider = tns({
                container: sliderEvent,
                items: 1,
                slideBy: 1,
                mouseDrag: false,
                autoplay: false,
                speed: 700,
                animateDelay: 5000,
                nav: true,
                loop: true,
                gutter: 30,
                lazyload: false,
                center: true,
                autoplayButtonOutput: false,
                controls: false,
                navPosition: 'bottom',
                responsive: {
                    640: {
                        edgePadding: 20,
                        gutter: 30,
                        items: 1
                    },
                    768: {
                        items: 1,
                    },
                    1024: {
                        items: 1
                    }
                }
            });
        }
    },
    whatWeDoSlider: function () {
        const sliders = document.querySelectorAll('.what-we-do-slider');

        if (sliders.length) {
            for (const sliderEl of sliders) {

                const slider = tns({
                    container: sliderEl,
                    items: 1,
                    slideBy: 1,
                    mouseDrag: false,
                    autoplay: false,
                    loop: false,
                    controlsPosition: 'bottom',
                    nav: false,
                    gutter: 30,
                    lazyload: false,
                    swipeAngle: false,
                    autoplayButtonOutput: false,
                    controls: false,
                });
                const prev = document.querySelector('.what-we-do-prev');
                if(prev) {
                    prev.addEventListener('click', function () {
                        slider.goTo('prev');
                    });
                }
                const next = document.querySelector('.what-we-do-next');
                if(next) {
                    next.addEventListener('click', function () {
                        slider.goTo('next');
                    });
                }
            }
        }
    }
}