export default {
    resellers: function () {
        const mainResellers = document.querySelector('.main-resellers');

        if (mainResellers) {
/*            var map;
            function initMap() {
                map = new google.maps.Map(document.getElementById('map'), {
                    center: {lat: -34.397, lng: 150.644},
                    zoom: 8
                });
            }*/

/*            for (const reseller of data) {
                console.log(reseller);
                const name = reseller.name;
                const addresses = reseller.addresses;

                for (const address of addresses) {

                    // Creo l'oggetto contenente le coordinate (prima longitudine e poi latitudine)
                    let LonLat = new OpenLayers.LonLat(address.coordinates.longitude, address.coordinates.latitude)
                        .transform(
                            new OpenLayers.Projection("EPSG:4326"), // Transformazione da WGS 1984..
                            Mappa.getProjectionObject() // .. a Spherical Mercator Projection
                        );


                    // Creo una variabile contenete il layer dei marker poi collego il layer dei markers alla mappa
                    let LayerMarkers = new OpenLayers.Layer.Markers("Markers");
                    Mappa.addLayer(LayerMarkers);

                    // Aggiungo al layer dei marker un marker (utilizzando l'oggetto lonLat per le coordinate)
                    LayerMarkers.addMarker(new OpenLayers.Marker(LonLat));

                    // Imposto le coordinate di lonLat come centro della mappa di partenza
                    Mappa.setCenter(LonLat, zoom);
                }
            }*/
        }
    }
}
