import gsap from 'gsap';

export default {
    panel: function () {
        (function ($) {
            'use strict';

            $.fn.notificationPanel = function(){

                return this.each(function() {

                    let element = $(this);
                    element.addClass('floating-notification--revealed');
                    gsap.to(element, 1, {
                        autoAlpha: 1,
                        y: 0,
                        // ease: Back.easeOut.config(1)
                    });

                    setTimeout(function () {
                        gsap.to(element, .7, {
                            autoAlpha: 0,
                            y: '100%',
                            //   ease: Back.easeOut.config(1)
                        });
                    }, 6000);

                    $('.btn', element).click(function () {
                        gsap.to(element, .7, {
                            autoAlpha: 0,
                            y: '100%',
                            //    ease: Back.easeOut.config(1)
                        });
                    });

                });
            };

        })(jQuery);
    }
}
