import gsap from 'gsap';

export default {
    menuDropdown: function () {
        window.addEventListener('load', function () {
            gsap.to(".load-fix", .5,{
                autoAlpha: 0,
                yPercent: -100,
                xPercent: -50,
            });
        });

        const desktopMenuItems = document.querySelectorAll('.top-navigation-desktop .has-children');
        const mobileMenuItems = document.querySelectorAll('.top-navigation-mobile .has-children');
        const menuOverlay = document.querySelector('.top-navigation__overlay');

        for (const item of mobileMenuItems) {
            let open = false;
            const link = item.querySelector('.top-navigation__nav__link');
            const container = item.querySelector('.top-navigation__nav__sub-menu > .container');

            link.addEventListener('click', function (event) {
                event.preventDefault();

                if (open) {
                    gsap.to(container, .5, {
                        onStart: function() {
                            item.classList.remove('active-dropdown');
                        },
                        height: 0,
                        onComplete: function () {
                            open = false;
                        }
                    });
                } else {
                    gsap.to(container, .5, {
                        onStart: function() {
                            item.classList.add('active-dropdown');
                        },
                        height: 'auto',
                        onComplete: function () {
                            open = true;
                        }
                    });
                }
            });
        }
    },
    headerFixed: function () {
        const header = document.querySelector('header');
        const headerHeight = header.clientHeight;
        const subMenu = document.querySelector('.sub-navigation');

        let headerStatusChecker = function () {
            if (window.scrollY > headerHeight) {
                header.classList.add('top-navigation--scrolling');

                if (subMenu) {
                    subMenu.classList.add('sub-navigation--scrolling');
                }
            } else {
                header.classList.remove('top-navigation--scrolling');

                if (subMenu) {
                    subMenu.classList.remove('sub-navigation--scrolling');
                }
            }
        } //FORCE DEPLOY

        headerStatusChecker();

        document.addEventListener('scroll', function () {
            headerStatusChecker();
        });

        if (subMenu) {
            const title = subMenu.querySelector('.sub-navigation__title');
            const panel = subMenu.querySelector('.sub-navigation__panel');
            let isClosed = true;

            title.addEventListener('click', function (event) {

                if (window.matchMedia("(max-width: 991px)").matches) {
                    event.preventDefault();

                    if (isClosed === true) {
                        gsap.to(panel, .5, {
                            onStart: function () {
                                title.classList.add('open');
                            },
                            height: 'auto',
                            onComplete: function () {
                                isClosed = false
                            }
                        });
                    } else {
                        gsap.to(panel, .5, {
                            onStart: function () {
                                title.classList.remove('open');
                            },
                            height: 0,
                            onComplete: function () {
                                isClosed = true;
                            }
                        });
                    }
                }
            });
        }
    },
    mobileMenu: function () {
        const html = document.querySelector('html');
        const burger = document.querySelector('.top-navigation__burger');
        const header = document.querySelector('header');

        let isOpen = false;

        burger.addEventListener('click', function () {
            let hasAlreadyScrolled = false;
            if (header.classList.contains('top-navigation--scrolling')) {
                hasAlreadyScrolled = true;
            }

            if (isOpen === false) {
                isOpen = true;
                html.classList.add('overflow-hidden');

                if (hasAlreadyScrolled === true) {
                    header.classList.add('top-navigation--show-mobile-nav');
                } else {
                    header.classList.add('top-navigation--scrolling', 'top-navigation--show-mobile-nav');
                }
            } else {
                isOpen = false;
                html.classList.remove('overflow-hidden');
                header.classList.remove('top-navigation--show-mobile-nav');
            }
        })
    }
}
