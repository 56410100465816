import LazyLoad from "vanilla-lazyload";

export default {
  lazyLoad: function () {
    const lazyLoadInstance = new LazyLoad({
      elements_selector: ".lazy"
    });
  }
}

jQuery(document).ready(function() {
    function reveal() {
        let reveals = document.querySelectorAll(".reveal");

        for (let i = 0; i < reveals.length; i++) {
            let windowHeight = window.innerHeight;
            let elementTop = reveals[i].getBoundingClientRect().top;
            let elementVisible = 150;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            }
        }
    }

    window.addEventListener("scroll", reveal);

    // To check the scroll position on the page load
    reveal();
});
