import gsap from "gsap";
import {tns} from "tiny-slider/src/tiny-slider"

document.getElementById('input_form').addEventListener('submit', function (event) {
    event.preventDefault();
    var search_data = document.getElementById('input_search').value;

    var lang = document.getElementsByTagName("html")[0].getAttribute('lang');
    if(lang == 'en_US') {
        lang = 'usa';
    }

   var url = window.location.origin + '/' + lang + '/search';
   if (search_data) {
       document.getElementById('input_form').action=url;
       document.getElementById('input_form').submit();
       return true;
   }
   return false;
});

const modalTriggers = document.querySelectorAll('.popup-trigger')
const modalCloseTrigger = document.querySelector('.popup-modal__close')
const bodyBlackout = document.querySelector('.body-blackout')

modalTriggers.forEach(trigger => {
    trigger.addEventListener('click', () => {
        const { popupTrigger } = trigger.dataset
        const popupModal = document.querySelector(`[data-popup-modal="${popupTrigger}"]`)

        popupModal.classList.add('is--visible')
        bodyBlackout.classList.add('is-blacked-out')
        document.getElementById('input_search').focus();

        popupModal.querySelector('.popup-modal__close').addEventListener('click', () => {
            popupModal.classList.remove('is--visible')
            bodyBlackout.classList.remove('is-blacked-out')
        })

        bodyBlackout.addEventListener('click', () => {
            // TODO: Turn into a function to close modal
            popupModal.classList.remove('is--visible')
            bodyBlackout.classList.remove('is-blacked-out')
        })
    })
})

function getSecoTicker() {
   /* jQuery.getJSON('https://corporate.seco.com/Feed/ShareSnapshot.js', function(data) {
        localStorage.setItem('ticker', JSON.stringify(data));
        renderTicker();
    });*/

    var request = new XMLHttpRequest();
    request.open('GET', 'https://corporate.seco.com/Feed/ShareSnapshot.js', true);
    request.onload = function() {
        if (request.status >= 200 && request.status < 400) {
            // Success!
            localStorage.setItem('ticker', '');
            var data = JSON.parse(request.responseText);
            localStorage.setItem('ticker', JSON.stringify(data));
            renderTicker();
        } else {
            // We reached our target server, but it returned an error
        }
    };
    request.onerror = function() {
        // There was a connection error of some sort
    };
    request.send();

}
function renderTicker() {
    var tickerElem;
    var tickerElemMobile;
    tickerElem = document.getElementById('ticker');
    tickerElemMobile = document.getElementById('ticker-mobile');
    if(tickerElem !== null && tickerElemMobile !== null) {
        var ticker = JSON.parse(localStorage.getItem('ticker'));

        var content = ticker.SECO.symbol +
            ' € ' +
            ticker.SECO.lastPrice.toFixed(2) +
            ' ' +
            (ticker.SECO.trend == "up" ?
                    ' <span class="up">' + ticker.SECO.percentChange + '</span>' :
                    ' <span class="down">' + ticker.SECO.percentChange + '</span>'
            );

        tickerElem.innerHTML = content;
        tickerElemMobile.innerHTML = content;
    }


}
if( localStorage.getItem('ticker') ) {
    var ticker = JSON.parse(localStorage.getItem('ticker'));
    var lastUpdate = ticker.SECO.timeStamp;
    var now = new Date();
    var UTCseconds = (now.getTime() - now.getTimezoneOffset()*60*1000);
    var difference = Math.floor(((UTCseconds - lastUpdate)/1000) % 60)
    if (difference < 300) {
        //renderTicker();
        getSecoTicker();
    } else {
        getSecoTicker();
    }
} else {
    getSecoTicker();
}

if($('#area').val()){
    $('.reseller-list').addClass('active');
} else {
    $('.reseller-list').removeClass('active');
}

export default {
    formSelect: function () {
        (function ($) {
            'use strict';
        $('.select-child').parent().parent().addClass('width-100-pl').hide();
        $('.parent').parent().parent().addClass('width-100').append("<span class='caret'></span>");


            $(".width-100").find("input[type='checkbox']").each(function(){
                if ($(this).prop('checked')==true){
                    var elem =  $(this).closest('.width-100');
                    elem.addClass('open');
                    elem.next('.width-100-pl').show();
                }
            });

            $(".width-100-pl").find("input[type='checkbox']").each(function(){
                if ($(this).prop('checked')==true){
                    var elem = $(this).closest('.width-100-pl').show().prev('.width-100');
                    elem.addClass('open');
                    elem.find('input').trigger('click');
                }
            });

            $('.caret').on("click", function(e) {
                var elem = $(this).closest('.width-100');
                elem.toggleClass('open');
                elem.next(".width-100-pl").slideToggle();
            });

            $('.width-100 input').on("click", function(e) {
                var elem = $(this).closest('.width-100');
                elem.toggleClass('open');
                elem.next(".width-100-pl").slideToggle();
            });

            $('.width-100-pl input[type="checkbox"]').on("click", function(e) {
                var elem = $(this).closest('.width-100-pl');
                elem.prev().find('input[type="checkbox"]').prop('checked', true);
            });

        })(jQuery);
    },
    selectLangCustom: function () {
        (function ($) {
            'use strict';
            $(".customSelect, .customSelect-m").on("click", ".init", function() {
                $('.lang-offc-container').addClass('open');
                $('body').css('overflow', 'hidden');
            });
            $(".customSelect-m").on("click", function() {
                $('.top-navigation__burger').trigger('click');
                $('.lang-offc-container').addClass('open');
                $('body').css('overflow', 'hidden');
            });
            $(".close").on("click", function() {
                $('.lang-offc-container').removeClass('open');
                $('body').css('overflow', 'initial');
            });

            $('.tab-menu li a').on('click', function(){
                var target = $(this).attr('data-rel');
                $('.tab-menu li a').removeClass('active');
                $(this).addClass('active');
                $("#"+target).fadeIn('slow').siblings(".tab-box").hide();
                return false;
            });
        })(jQuery);
    }
}

let headerBlock = $('.top-navigation');

$(window).scroll(function () {
    if( $(window).scrollTop() > 1 && !headerBlock.hasClass('sticky')) {
        headerBlock.addClass('sticky');
    } else if ($(window).scrollTop() === 0) {
        headerBlock.removeClass('sticky');
    }
});
