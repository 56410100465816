export default {
    newsletter: function (event) {
        const footer = document.querySelector('.bottom-navigation');


        if(footer) {
            footer.addEventListener('submit', function(event) {
                const email = $('#newsletter_subscribe_form_email');
                const checkbox = $('#exampleCheck1');

                if (email.val() === "" || (!checkbox.is(':checked'))) {
                    event.preventDefault();
                    $('#newsletter_subscribe_form_email + .required-field').remove();
                    $('.form-check.hide-block + .required-field').remove();

                    if (email.val() === "") {
                        email.after('<p class="mt-4 text-sans-serif-secondary text-secondary font-weight-bold required-field">' + window.newsletter.error.input + '</p>');
                    } else {
                        $('#newsletter_subscribe_form_email + .required-field').remove();
                    }
                    if (!checkbox.is(':checked')) {
                        $('.form-check.hide-block').after('<p class="mt-4 required-field text-sans-serif-secondary text-secondary font-weight-bold required-field">' + window.newsletter.error.checkbox + '</p>');
                    }  else {
                        $('.form-check.hide-block + .required-field').remove();
                    }
                }
            })
        }
    },

    check: function (array, type){
        if(array.length > 0) {
            let parentDiv;
            for(let i = 0; i < array.length; i++){
                if (parentDiv === null || parentDiv !== array[i].closest('.formbuilder-container-fieldset')){
                    parentDiv = array[i].closest('.formbuilder-container-fieldset');
                    parentDiv.previousElementSibling.click();
                }

                if(type == 'checkbox') {
                    array[i].checked = true;
                }else if(type == 'option') {
                    array[i].selected = true;
                }

            }
        }
    },

    getMatching: function (checksArray,value) {
        let matching = [];
        for(let i = 0; i < checksArray.length; i++){
            if(checksArray[i].getAttribute('value').startsWith(value)){
                matching.push(checksArray[i]);
            }
        }
        return matching;
        /*if(matching.length){
            return matching;
        } else {
            throw {name: 'NotFoundException', message : 'Value could not be found'}
        }*/
    },


    execute: function() {
        try{
            const hashValue = decodeURI(window.location.hash.substr(1));
            const checkboxes = document.querySelectorAll('.custom-checkbox input[type="checkbox"]');
            //const selects = document.querySelectorAll('.form-group .select-child option');
            const childs = document.querySelectorAll('.form-group .select-child input[type="checkbox"]');
            if (hashValue.length) {
                this.check(this.getMatching(checkboxes, hashValue), 'checkbox')
                this.check(this.getMatching(childs, hashValue), 'checkbox')
                //this.check(this.getMatching(selects, hashValue), 'option')
            }


        } catch (e){
            if (e.name === 'NotFoundException'){
                console.error(e.name + ': ' + e.message);
            } else {
                throw e;
            }
        }
    }
}
