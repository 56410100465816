import gsap from 'gsap';
import {requestAnimationFrame, cancelAnimationFrame} from 'animation-frame-polyfill';

export default {
    pageLoadedChecker: function () {
        const html = document.querySelector('html');

        function isCoarse() {
            const match = matchMedia('(pointer:coarse)').matches;

            if (match) {
                html.classList.add('coarse');

                return true;
            }
        }

        let everythingLoaded = setInterval(function () {
            if (/loaded|complete/.test(document.readyState)) {
                clearInterval(everythingLoaded);

                html.classList.add('page-loaded');

                isCoarse();

            }
        }, 10);
    },
    tabBox: function () {
        const tabsContainers = document.querySelectorAll('.box-tab');

        if (tabsContainers.length > 0) {
            for (const tabContainer of tabsContainers) {
                if (!tabContainer.classList.contains('box-tab--static')) {
                    const tabs = tabContainer.querySelectorAll('.box-tab__icon');

                    if (tabs.length) {
                        const totalItems = tabs.length;
                        const firstItem = tabs[0];
                        const lastItem = tabs[totalItems - 1];

                        firstItem.classList.add('first-item');
                        lastItem.classList.add('last-item');

                        const initialInterval = setInterval(function () {
                            const activeTab = tabContainer.querySelector('.box-tab__icon--active');

                            if(activeTab && !activeTab.classList.contains('no-active-automatic')) {
                                activeTab.classList.remove('box-tab__icon--active');

                                const activeElement = tabContainer.querySelector('.box-tab__content--active');
                                activeElement.classList.remove('box-tab__content--active');


                                if (activeTab === lastItem) {
                                    firstItem.classList.add('box-tab__icon--active');

                                    const linkedElement = tabContainer.querySelector(firstItem.getAttribute('href'));
                                    linkedElement.classList.add('box-tab__content--active');
                                } else {
                                    const nextTab = activeTab.parentNode.nextElementSibling.querySelector('.box-tab__icon');

                                    nextTab.classList.add('box-tab__icon--active');

                                    const linkedElement = tabContainer.querySelector(nextTab.getAttribute('href'));
                                    linkedElement.classList.add('box-tab__content--active');
                                }
                            }



                        }, tabContainer.dataset.timer);


                        for (const tab of tabs) {
                            tab.addEventListener('click', function (event) {
                                event.preventDefault();
                                const activeTab = tabContainer.querySelector('.box-tab__icon--active');
                                const activeElement = tabContainer.querySelector('.box-tab__content--active');
                                const linkedElement = tabContainer.querySelector(tab.getAttribute('href'));

                                activeTab.classList.remove('box-tab__icon--active');
                                activeElement.classList.remove('box-tab__content--active');
                                tab.classList.add('box-tab__icon--active');
                                linkedElement.classList.add('box-tab__content--active');
                            });
                        }
                    }
                }
            }
        }
    },
    tabBoxThree: function () {
        const tabsContainers = document.querySelectorAll('.box-tab-three');
        const hh = [];
        if (tabsContainers.length > 0) {
            for (const tabContainer of tabsContainers) {
                if (!tabContainer.classList.contains('box-tab--static')) {
                    const tabs = tabContainer.querySelectorAll('.box-tab__icon');

                    if (tabs.length) {
                        const totalItems = tabs.length;
                        const firstItem = tabs[0];
                        const lastItem = tabs[totalItems - 1];

                        firstItem.classList.add('first-item');
                        lastItem.classList.add('last-item');

                        const initialInterval = setInterval(function () {
                            const activeTab = tabContainer.querySelector('.box-tab__icon--active');

                            if(activeTab && !activeTab.classList.contains('no-active-automatic')) {
                                activeTab.classList.remove('box-tab__icon--active');

                                const activeElement = tabContainer.querySelector('.box-tab__content--active');
                                activeElement.classList.remove('box-tab__content--active');
                                var linkedElement;

                                if (activeTab === lastItem) {
                                    firstItem.classList.add('box-tab__icon--active');

                                    linkedElement = tabContainer.querySelector(firstItem.getAttribute('href'));
                                    linkedElement.classList.add('box-tab__content--active');
                                } else {
                                    const nextTab = activeTab.parentNode.nextElementSibling.querySelector('.box-tab__icon');

                                    nextTab.classList.add('box-tab__icon--active');
                                    linkedElement = tabContainer.querySelector(nextTab.getAttribute('href'));
                                    linkedElement.classList.add('box-tab__content--active');
                                }
                                //Controller height of box
                                let hData = linkedElement.offsetHeight;
                                hh.push(hData);
                            }

                        }, 7000);

                        for (const tab of tabs) {
                            tab.addEventListener('click', function (event) {
                                event.preventDefault();
                                const activeTab = tabContainer.querySelector('.box-tab__icon--active');
                                const activeElement = tabContainer.querySelector('.box-tab__content--active');
                                const linkedElement = tabContainer.querySelector(tab.getAttribute('href'));

                                activeTab.classList.remove('box-tab__icon--active');
                                activeElement.classList.remove('box-tab__content--active');
                                tab.classList.add('box-tab__icon--active');
                                linkedElement.classList.add('box-tab__content--active');

                                //Controller height of box
                                let hData = linkedElement.offsetHeight;
                                hh.push(hData);
                            });
                        }
                    }
                }
            }
        }
        //Controller height of box
        let squareTrigger = document.querySelectorAll('.box-tab-three .box-tab__icon');
        let firstElem =  document.querySelector('.box-tab-three .first');
        let hGeneral = document.querySelector('.box-tab-three .hGeneral')


        for (let j = 0; j < squareTrigger.length; j++) {
            let currentSquare = squareTrigger[j];
            currentSquare.click();
        }

        if(firstElem !== null) {
            firstElem.click();
        }

        let max = Math.max(...hh);

        if(hGeneral !== null) {
            hGeneral.setAttribute("style", "height:" + max + "px;");
        }

        let el = document.querySelectorAll('.box-tab-three .box-tab__content');

        for (let i = 0; i < el.length; i++) {
            let currentEl = el[i];
            currentEl.setAttribute("style", "height:" + max + "px;");
        }
    },
    tabsBanner: function () {
        const tabs = document.querySelectorAll('.tab');

        if (tabs.length) {
            for (const tab of tabs) {
                const tabsTitles = tab.querySelectorAll('.tab__nav__title');
                const tabContainer = tab.querySelector('.tab__container');
                let currentActiveTab = tab.querySelector('.tab__content--active');
                const tabSelect = tab.querySelector('.tab-select');
                let canClick = true;

                if (tabSelect) {
                    tabSelect.addEventListener('change', function (event) {
                        document.querySelector('[data-tab="' + event.target.value + '"]').click();
                    })
                }

                for (const tabTitle of tabsTitles) {
                    tabTitle.addEventListener('click', function () {

                        if (!this.classList.contains('tab__nav__title--active') && canClick === true) {
                            tab.querySelector('.tab__nav__title--active').classList.remove('tab__nav__title--active');
                            this.classList.add('tab__nav__title--active');

                            const nextTab = document.getElementById('tab-' + this.getAttribute('data-tab') + '');


                            gsap.to(currentActiveTab, .2, {
                                onStart: function () {
                                    canClick = false
                                },
                                autoAlpha: 0
                            });

                            gsap.to(tabContainer, .3, {
                                height: nextTab.clientHeight
                            });

                            currentActiveTab.classList.remove('tab__content--active');

                            currentActiveTab = nextTab;

                            gsap.to(currentActiveTab, .4, {
                                autoAlpha: 1,
                                delay: .3,
                                onComplete: function () {
                                    canClick = true
                                }
                            });

                            currentActiveTab.classList.add('tab__content--active');

                            if (tabSelect) {
                                tabSelect.value = this.getAttribute('data-tab');
                            }
                        }
                    })
                }
            }
        }
    },
    revealArticles: function () {
        const toggleEl = document.querySelector('.reveal-articles');

        if (toggleEl) {
            const hiddenArticles = document.querySelectorAll('.remaining-news');

            toggleEl.addEventListener('click', function () {

                for (let article of hiddenArticles) {
                    article.classList.remove('d-none');
                }

                toggleEl.classList.add('d-none');
            });
        }
    },
    revealEvents: function () {
        const toggleEvents = document.querySelectorAll('.reveal-events');

        if (toggleEvents.length) {
            for (const button of toggleEvents) {
                button.addEventListener('click', function () {
                    const year = button.getAttribute('data-show');

                    const selectedTab = document.getElementById('tab-' + year);

                    const elements = selectedTab.querySelectorAll('.d-none');

                    for (const element of elements) {
                        element.classList.remove('d-none');
                    }

                    button.classList.add('d-none');
                });
            }
        }
    },
    sectionVideo: function () {
        const sections = document.querySelectorAll('.section-video');

        if (sections.length) {
            for (const section of sections) {
                const popUp = document.querySelector('.section-video__pop-up');
                const overlay = section.querySelector('.section-video__pop-up__overlay');
                const html = document.querySelector('html');
                const videoControl =  section.querySelector('.video-control');
                let show = false;

                section.addEventListener('click', function () {
                    if (!show) {
                        gsap.to(popUp, .5, {
                            onStart: function () {
                                html.classList.add('overflow-hidden');
                            },
                            autoAlpha: 1,
                            onComplete: function () {
                                show = true;
                            }
                        });
                    }
                });

                overlay.addEventListener('click', function () {
                    if (show) {
                        gsap.to(popUp, .3, {
                            autoAlpha: 0,
                            onComplete: function () {
                                html.classList.remove('overflow-hidden');
                                show = false;
                                videoControl.pause();
                            }
                        })
                    }
                })
            }
        }
    },
    stickyYears: function () {
        const yearsList = document.querySelector('.year-list');

        if (yearsList) {
            const offsetY = yearsList.getBoundingClientRect().y + 15;
            const anchors = yearsList.querySelectorAll('a');

            for (const anchor of anchors) {
                anchor.addEventListener('click', function (event) {
                    event.preventDefault();

                    let href = anchor.getAttribute('href');

                    href = href.replace('#', '');

                    const sectionOffset = document.getElementById(href).offsetTop - 150;

                    window.scroll(0, sectionOffset);
                });
            }

            const offsetChecker = function () {
                if (window.scrollY > offsetY) {
                    yearsList.classList.add('year-list--sticky');
                } else {
                    yearsList.classList.remove('year-list--sticky');
                }

                requestAnimationFrame(offsetChecker);
            };

            offsetChecker();
        }
    }
}
