'use strict';

import global from './_global';
import lazyLoad from './_lazy-load';
import header from './_header';
import footer from './_footer';
import sliders from './_sliders';
import filters from './_filters';
import maps from './_maps';
import notification from './_notification';
import forms  from './_forms';
import overrides from './boraso/_overrides';

document.addEventListener("DOMContentLoaded", function() {
    // page initialization
    global.pageLoadedChecker();
    lazyLoad.lazyLoad();

    // header
    header.headerFixed();
    header.mobileMenu();
    header.menuDropdown();

    // footer
    footer.mobileTab();
    footer.languageSwitcher();

    // sliders
    sliders.documentsSlider();
    sliders.eventSlider();
    sliders.highlightSlider();
    sliders.imageBannerSlider();
    sliders.newsSlider();
    sliders.productSlider();
    sliders.storySlider();
    sliders.casiStudioSlider();
    sliders.productThumbSlider();
    sliders.techRelatedSlider();
    sliders.solutionsSlider();
    sliders.whatWeDoSlider();
    sliders.sliderVideoHero();
    sliders.diconoDiNoiSlider();

    // tabs
    global.tabBox();
    global.tabBoxThree();
    global.tabsBanner();

    // filters
    filters.accordeon();
    filters.filter();
    filters.fieldsetAccordeon();
    filters.sidebar();
    filters.spontaneouscandidacy();

    // UI behaviours
    global.revealArticles();
    global.revealEvents();
    global.sectionVideo();
    global.stickyYears();

    // maps
    maps.resellers();

    //notifications
    notification.panel();

    // forms
    forms.newsletter();
    forms.execute();

    overrides.formSelect();
    overrides.selectLangCustom();

});

