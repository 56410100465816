import gsap from 'gsap';

export default {
    filter: function () {
        const form = document.getElementById('js_filterfield');
        if (form) {
            const checkboxes = form.querySelectorAll('#js_filterfield .checkbox input');
            const inputs = form.querySelectorAll('#js_filterfield .radio input');
            let formSubmit = function (element) {
                element.addEventListener('change', function () {
                    form.submit();
                });
            };

            for (const checkbox of checkboxes) {
                formSubmit(checkbox);
            }

            for (const input of inputs) {
                formSubmit(input);
            }
        }
    },
    accordeon: function () {
        const accordeons = document.querySelectorAll('.filter__title');

        if (accordeons.length) {
            for (const accordeon of accordeons) {
                const panel = accordeon.nextElementSibling;
                const btnPopUp = panel.querySelector('.work-with-us-form-button');

                let closed = true;

                accordeon.addEventListener('click', function () {
                    if (closed === false) {
                        gsap.to(panel, .4, {
                            onStart: function () {
                                accordeon.classList.remove('filter__title--opened')
                            },
                            height: 0,
                            onComplete: function () {
                                closed = true;
                            }
                        });
                    } else {
                        gsap.to(panel, .4, {
                            onStart: function () {
                                accordeon.classList.add('filter__title--opened')
                            },
                            height: 'auto',
                            onComplete: function () {
                                closed = false;
                            },
                        });
                    }
                });

                if (btnPopUp) {
                    //console.log(accordeon);
                   const html = document.querySelector('html');
                    const popUp = document.querySelector('.work-with-us__modal');
                    const closeButtons = popUp.querySelectorAll('.work-with-us__modal__close');
                    const formTitle = document.querySelector('.work-with-us__modal__title');

                    btnPopUp.addEventListener('click', function (event) {
                        event.preventDefault();

                        formTitle.innerText = accordeon.innerText;
                        if (document.querySelector('.job-position')) {
                            document.querySelector('.job-position').value = accordeon.innerText;

                            if(document.querySelector('.dynamic-email')) {
                                const options = document.querySelector('.dynamic-email').options;

                                Array.prototype.forEach.call(options, function(option, index) {

                                    if(option.textContent == document.querySelector('.job-position').value) {
                                        return option.selected = 'selected';
                                    }

                                });

                            }
                        }

                        gsap.to(popUp, .5, {
                            onStart: function() {
                                html.classList.add('overflow-hidden');
                            },
                            autoAlpha: 1
                        });
                    });

                    for(const button of closeButtons) {
                        button.addEventListener('click', function() {
                            gsap.to(popUp, .3, {
                                autoAlpha: 0,
                                onComplete: function() {
                                    html.classList.remove('overflow-hidden');
                                },
                            });
                        });
                    }
                }
            }
        }
    },
    fieldsetAccordeon: function () {
        const formGroups = document.querySelectorAll('.form-group');

        if (formGroups.length) {
            for (const group of formGroups) {
                const legend = group.querySelector('legend.col-form-label');
                const panel = group.querySelector('.formbuilder-container-fieldset');


                if (legend) {
                    let closed = true;

                    legend.addEventListener('click', function () {
                       if(closed) {
                           gsap.to(panel, .5, {
                               onStart: function() {
                                   legend.classList.add('opened');
                               },
                               height: 'auto',
                               onComplete: function() {
                                   closed = false;
                               }
                           });
                       } else {
                           gsap.to(panel, .5, {
                               onStart: function() {
                                   legend.classList.remove('opened');
                               },
                               height: 0,
                               onComplete: function() {
                                   closed = true;
                               }
                           });
                       }
                    });
                }
            }
        }

        /*   legend.addEventListener('click', function () {
               if (closed === false) {
                   gsap.to(panel, .4, {
                       onStart: function () {
                           legend.classList.remove('filter__title--opened')
                       },
                       height: 0,
                       onComplete: function () {
                           closed = true;
                       }
                   });
               } else {
                   gsap.to(panel, .4, {
                       onStart: function () {
                           legend.classList.add('filter__title--opened')
                       },
                       height: 'auto',
                       onComplete: function () {
                           closed = false;
                       },
                   });
               }
           });
       }
   }*/
    },
    sidebar: function () {
        const sidebar = document.querySelector('.filters-sidebar');
        const sidebarToggler = document.querySelector('.btn-filters-mobile--filter');
        const close = document.querySelector('.btn-close-sidebar');
        let isOpen = false;

        if (sidebar) {
            sidebarToggler.addEventListener('click', function (event) {
                event.preventDefault();

                if (isOpen === false) {
                    gsap.to(sidebar, .5, {
                        autoAlpha: 1,
                        onComplete: function () {
                            isOpen = true;
                        }
                    });
                } else {
                    gsap.to(sidebar, .5, {
                        autoAlpha: 0,
                        onComplete: function () {
                            isOpen = false;
                        }
                    });
                }

            });

            close.addEventListener('click', function (event) {
                event.preventDefault();

                gsap.to(sidebar, .5, {
                    autoAlpha: 0,
                    onComplete: function () {
                        isOpen = false;
                    }
                });
            });
        }
    },
    spontaneouscandidacy: function () {

        const btnPopUp = document.querySelector('.button-spontaneous-candidacy');

        if (btnPopUp) {
            const html = document.querySelector('html');
            const popUp = document.querySelector('.work-with-us-spontaneous-candidacy__modal');
            const closeButtons = popUp.querySelectorAll('.work-with-us__modal__close');
            const formTitle = document.querySelector('.work-with-us__modal__title');

            btnPopUp.addEventListener('click', function (event) {
                event.preventDefault();

                gsap.to(popUp, .5, {
                    onStart: function() {
                        html.classList.add('overflow-hidden');
                    },
                    autoAlpha: 1
                });
            });

            for(const button of closeButtons) {
                button.addEventListener('click', function() {
                    gsap.to(popUp, .3, {
                        autoAlpha: 0,
                        onComplete: function() {
                            html.classList.remove('overflow-hidden');
                        },
                    });
                });
            }
        }

    }
}
